<template>
    <div
        class="mission"
    >
        <div
            class="mission-header"
        >
            <img
                :src="`${mission.imageUrl}`"
                alt="mission image"
                class="mission-image"
            >
            <div class="mission-status">
                <span v-if="mission.completed" class="span-completed">
                    <font-awesome-icon :icon="[ 'far', 'check-circle' ]" class="mr-2" /> {{ $t('mission.completed') }} !
                </span>
                <span v-else-if="mission.locked" class="span-locked">
                    {{ $t('mission.locked') }}
                </span>
            </div>
        </div>
        <div class="mission-info">
            <div class="tasks-count">
                {{ mission.tasks.length }} {{ mission.tasks.length === 1 ? $t('mission.task'): $t('mission.tasks') }}
            </div>
            <div class="title-play-container">
                <div class="mission-title line-clamp">
                    {{ mission.alias }}
                </div>
                <div
                    class="play-btn"
                    :class="{ 'disabled': !clickable }"
                    @click="goToTasks(mission)"
                > {{ $t('mission.pressHereToPlay') }}</div>
            </div>
        </div>
    </div>
</template>

<script>
    import config from '@/mixins/config'

    export default {
        name: 'Mission',
        mixins: [ config ],
        props: {
            mission: {
                type: Object,
                required: true
            }
        },
        computed: {
            clickable() {
                return !this.mission.locked || this.$store.getters.isPreviewMode
            }
        },
        methods: {
            goToTasks(mission) {
                if (!this.clickable || mission.tasks.length === 0) {
                    return
                }
                this.$router.push(
                    {
                        path: `/missions/${mission.id}`
                    }
                )
            }
        }
    }
</script>

<style lang="scss" scoped>
.mission {
    border-radius: 10px;
    height: 100%;
    width: 100%;
    padding: 0.75rem;
    background-color: var(--white);
    display: flex !important;
    flex-direction: column;
    gap: 0.5rem;
}

.mission-header {
    position: relative;
}

.mission-image {
    width: 100%;
    height: 170px;
    object-fit: contain;
}

.mission-status {
    padding-right: 0;
}

.mission-status > .span-locked {
    position: absolute;
    top: 5%;
    right: 0;
    display: block;
    background: var(--error);
    padding: 8px;
    font-weight: 700;
    font-size: 0.8rem;
    text-align: center;
    color: var(--white);
    box-shadow: 0 4px 8px rgb(0 0 0 / 25%);
    border-radius: 10px 0 0 10px;
}

.mission-status > .span-completed {
    position: absolute;
    top: 5%;
    right: 0;
    display: block;
    background: var(--secondary-color);
    padding: 8px;
    font-weight: 700;
    font-size: 0.8rem;
    text-align: center;
    color: var(--white);
    box-shadow: 0 4px 8px rgb(0 0 0 / 25%);
    border-radius: 10px 0 0 10px;
}

.mission-info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex-grow: 1;
}

.tasks-count {
    color: var(--secondary-color);
    font-size: 14px;
    font-weight: 700;
    text-size-adjust: 100%;
}

.title-play-container {
    margin-top: auto;
    display: flex;
    justify-content: space-between;
    gap: 1rem;
    width: 100%;
    flex-direction: column;
}

.mission-title {
    color: var(--primary-color);
    font-size: 1rem;
    text-align: left;
}

.play-btn {
    background: var(--primary-color);
    color: var(--white);
    font-size: 1.25rem;
    font-weight: 700;
    text-size-adjust: 100%;
    min-width: 140px;
    height: 47px;
    box-shadow: 0 4px 20px rgb(0 0 0 / 10%);
    border-radius: 6px;
    display: grid;
    place-items: center;
    cursor: pointer;
    transition: all 0.1s ease-in;
}

.play-btn:hover {
    background: var(--secondary-color);
    color: var(--white);
}

.play-btn:active {
    transform: scale(0.9);
}

.disabled {
    background: var(--disabled-color);
    pointer-events: none;
}

.play-btn.disabled:active {
    transform: none;
}

/* stylelint-disable */

.line-clamp {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

/* stylelint-enable */

@media screen and (min-width: 768px) {
    .mission-image {
        width: 100%;
        height: 200px;
        object-fit: contain;
    }

    .title-play-container {
        flex-direction: row;
    }

    .mission-status > .span-locked {
        position: absolute;
        top: 5%;
        right: 0;
        display: block;
        background: var(--error);
        padding: 8px;
        font-weight: 700;
        font-size: 1rem;
        text-align: center;
        color: var(--white);
        box-shadow: 0 4px 8px rgb(0 0 0 / 25%);
        border-radius: 10px 0 0 10px;
    }

    .mission-status > .span-completed {
        position: absolute;
        top: 5%;
        right: 0;
        display: block;
        background: var(--secondary-color);
        padding: 8px;
        font-weight: 700;
        font-size: 1rem;
        text-align: center;
        color: var(--white);
        box-shadow: 0 4px 8px rgb(0 0 0 / 25%);
        border-radius: 10px 0 0 10px;
    }
}

@media screen and (min-width: 992px) {
    .mission-image {
        height: 245px;
    }

    .mission-status > .span-locked,
    .mission-status > .span-completed {
        font-size: 1.2rem;
    }
}

</style>
