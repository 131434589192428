<template>
    <div class="home-route">
        <div class="welcome-header">
            <b-img :src="branding.secondaryLogoUrl" class="logo" />
            <p class="header-first-text">{{ $t('home.headerFirstText') }}</p>
            <p class="header-second-text">{{ $t('home.headerSecondText') }}</p>
        </div>
        <div v-if="missionsLoading">
            <div class="text-center loading-text w-100 mt-4">{{ $t('home.loadingData') }}</div>
            <div class="loader-container">
                <loader />
            </div>
        </div>
        <div v-else>
            <slider
                ref="missionCarousel"
                class="mission-carousel"
                :class="{'carousel-padding': missions.length > 1}"
                :options="carouselOptions"
                @slide="slideChanged"
            >
                <slideritem
                    v-for="mission in missions"
                    :key="mission.id"
                    class="mission-slide"
                >
                    <mission
                        :mission="mission"
                    />
                </slideritem>
            </slider>
            <!-- <div
                        v-if="!hasBelowTwoMissions"
                        class="arrow left"
                        @click="prevSlide"
                    >
                        <font-awesome-icon :icon="['fas','chevron-left']" />
                    </div>

                    <div
                        v-if="!hasBelowTwoMissions"
                        class="arrow right"
                        @click="nextSlide"
                    >
                        <font-awesome-icon :icon="['fas','chevron-right']" />
                    </div> -->
            <div v-show="!hasBelowTwoMissions" class="carousel-pagination">
                <div
                    v-for="(bullet, index) in missions"
                    :key="bullet.src"
                    class="bullet"
                    :class="{active: carouselIndex === index}"
                    @click="slideTo(index)"
                />
            </div>
        </div>
        <div v-if="!missionsLoading && missions.length === 0">
            <div class="text-center loading-text w-100 mt-4">{{ $t('home.noMissionsYet') }}</div>
        </div>
    </div>
</template>

<script>
    import Mission from '@/components/Mission'
    import { slider, slideritem } from 'vue-concise-slider'
    import Loader from '@/components/Loader'
    import config from '@/mixins/config'

    export default {
        components: {
            Mission,
            Loader,
            slider,
            slideritem
        },
        mixins: [ config ],
        data() {
            return {
                carouselIndex: 0
            }
        },
        computed: {
            missions() {
                return this.$store.getters.getMissions
            },
            missionsLoading() {
                return this.$store.getters.getMissionsLoading
            },
            branding() {
                return this.$store.getters.getBranding
            },
            hasBelowTwoMissions() {
                return this.missions && this.missions.length < 2
            },
            carouselOptions() {
                return {
                    pagination: false,
                    centeredSlides: this.hasBelowTwoMissions
                }
            }
        },
        methods: {
            prevSlide() {
                this.$refs.missionCarousel.$emit('slidePre')
            },
            nextSlide() {
                this.$refs.missionCarousel.$emit('slideNext')
            },

            slideChanged(data) {
                this.carouselIndex = data.currentPage
            },
            slideTo(num) {
                const renderedCarousel = this.$refs.missionCarousel
                if (renderedCarousel) {
                    this.carouselIndex = num
                    renderedCarousel.$emit('slideTo', num)
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
.home-route {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    flex-grow: 1;
    justify-content: center;
    padding: 1rem 0 2.5rem;
}

.mission {
    outline: none;
    margin: 0 auto;
}

.loading-text {
    color: var(--main-content-text);
}

.welcome-header {
    margin-bottom: 1rem;
    padding-left: 15px;
    padding-right: 15px;
}

.header-first-text {
    font-size: 1.5rem;
    color: var(--logo-highlight-color);
}

.header-second-text {
    font-size: 1rem;
    color: var(--main-content-text);
}

.loader-container {
    width: 200px;
    height: 200px;
    margin: 0 auto;
}

.logo {
    width: 120px;
    margin: 0 auto;
}

.mission-carousel {
    margin-top: 1rem;
}

.carousel-padding {
    padding: 0 1.5rem;
}

.arrow {
    display: none;
    color: var(--logo-highlight-color);
    cursor: pointer;
    position: absolute;
    top: 50%;
}

.arrow.left {
    left: 0;
    padding: 0.5rem 1rem 0.5rem 0;
}

.arrow.right {
    right: 0;
    padding: 0.5rem 0 0.5rem 1rem;
}

.slider-container {
    white-space: normal;
    height: unset;
}

.slider-item {
    height: unset;
}

.mission-slide {
    display: grid;
    grid-template-columns: 1fr;
    align-items: flex-start;
    margin-right: 1.5rem;
    width: 100%;
    max-width: 310px;
    min-height: 270px;
}

.carousel-pagination {
    padding: 1.5rem 0;
    min-height: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.bullet {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: var(--light-highlight-color);
    cursor: pointer;
}

.bullet + .bullet {
    margin-left: 1rem;
}

.bullet.active {
    background: var(--logo-highlight-color);
}

@media screen and (min-width: 768px) {
    .header-first-text {
        font-size: 2.25rem;
    }

    .mission-slide {
        max-width: 360px;
        min-height: 280px;
        margin-right: 2rem;
    }

    .logo {
        width: 150px;
    }

    .mission-carousel {
        margin-top: 2rem;
    }

    .carousel-padding {
        padding: 0 2rem;
    }

    .arrow {
        display: block;
    }
}

@media screen and (min-width: 992px) {
    .home-route {
        justify-content: flex-start;
        padding: 1rem 0;
    }

    .mission-slide {
        width: 100%;
        max-width: 420px;
        margin-right: 2.5rem;
    }

    .carousel-padding {
        padding: 0 2.5rem;
    }
}

</style>
